*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

/* Defines 1rem (10px) */
html {
  font-size: 62.5%;
}

body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.6rem;
}

@media only screen and (max-width: 920px) {
  html {
    font-size: 55%;
  }
}

@media only screen and (max-width: 320px) {
  html {
    font-size: 52%;
  }
}