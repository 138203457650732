.projects {
  position: relative;
  height: 100%;
  /* border: solid; */
  color: white;
  padding: 50px 0px 100px 0px;
  background-color: rgb(67, 70, 86);
  font-family: "Montserrat", sans-serif;
}

.project-menu {
  /* border: solid; */
  text-align: center;
}

.project-menu button {
  font-family: "Montserrat", sans-serif;
  color: rgb(199, 199, 199);
  background-color: transparent;
  font-size: 2rem;
  padding: 1rem 0;
  margin: 1rem 3rem;
  /* border-radius: 5px; */
  cursor: pointer;
  border: none;
  border-bottom: solid;
  border-color: transparent;
  transition: all 0.2s ease;
}

.project-menu button:hover {
  border-color: #da4167;
}

.project-menu button:focus {
  outline: none;
}

button.active {
  border-color: #da4167;
  color: #da4167;
  font-weight: 600;
}

/********** Project Display **********/

.project--display {
  display: flex;
  flex-wrap: wrap;
  margin: 10rem;
  margin-top: 2rem;
  /* border: solid; */
  justify-content: center;
}

.project--container {
  width: 40rem;
  height: 40rem;
  border-radius: 5px;
  margin: 2rem;
  box-shadow: 4px 15px 20px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 0;
}
.project--img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  z-index: 0;
}

.project--container-overlay {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: 0.3s all ease;
  border-radius: 5px;
}

.project--container-overlay:hover {
  backdrop-filter: blur(5px) brightness(0.6);
}

.project--btn {
  align-self: center;
  border: white solid;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  border-radius: 2px;
  padding: 1rem 2rem;
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  transition: 1s all ease, 0.2s background-color;
  cursor: pointer;
}

/* TODO: button is fading out after white stuff */
.project--container-overlay:hover .project--btn {
  visibility: visible;
  opacity: 1;
}

.project--btn:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgb(63, 63, 63);
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  transition: all 1s;
}
.show {
  opacity: 0.7;
  z-index: 3;
  visibility: visible;
}

/* project-modal */

.project-modal {
  background-color: rgb(235, 235, 235);
  display: block;
  position: fixed;
  height: 95%;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 5px;
  opacity: 1;
  transition: all 1s ease;
}

.hide {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.project-modal--images {
  width: 100%;
  height: 50%;
  background-color: black;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.project-modal--image {
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  object-fit: contain;
  position: absolute;
  z-index: 0;
}

.project-modal--details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 4rem;
  height: 50%;
  color: rgb(18, 18, 18);
  overflow-y: scroll;
  overflow-x: hidden;
}

.project-modal--title {
  font-size: 4.5rem;
  font-weight: 500;
  font-family: "Ubuntu", sans-serif;
}

.project-modal--description {
  line-height: 1.5;
}

.project-modal--notes {
  font-size: 1.4rem;
  line-height: 1.5;
  padding: 1rem;
  border-radius: 3px;
  background-color: rgb(225, 225, 225);
}

.project--stack {
  margin: 0rem 3rem;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  visibility: none;
  opacity: 0;
  transition: all 1.2s;
}

.project--container-overlay:hover .project--stack {
  visibility: visible;
  opacity: 1;
  /* transform: translateY(50%); */
}

.project--stack span {
  margin: 1rem 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
}

.project-modal--links {
  /* border: solid; */
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.project-modal--link {
  text-decoration: none;
  font-size: 1.8rem;
  padding: 1rem 2rem;
  color: white;
  background-color: rgb(111, 117, 151);
  border: solid;
  border-color: rgb(111, 117, 151);
  margin: 0 1rem;
  transition: all 0.1s ease;
}

.project-modal--link:hover {
  background-color: rgb(125, 132, 168);
  border-color: rgb(125, 132, 168);
}

.transparent {
  background-color: transparent;
  color: rgb(93, 98, 128);
  font-weight: 600;
}

.transparent:hover {
  background-color: rgba(111, 117, 151, 0.1);
  color: rgb(93, 98, 128);
}

.project-modal--extra-links {
  /* border: solid; */
  margin: 0 -1rem;
  align-self: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.project-modal--link-icon {
  font-size: 1.4rem;
  margin-bottom: 2px;
}

.big-icon {
  font-size: 2.2rem;
  /* border: solid; */
  vertical-align: middle;
}

.extra-big-icon {
  font-size: 5rem;
  margin: 0 2rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.extra-big-icon:hover {
  transform: scale(1.2);
}

.youtube {
  color: rgb(235, 50, 35);
}

.trello {
  color: rgb(43, 105, 162);
}

.project-modal--exit {
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  font-size: 3rem;
  top: 1rem;
  right: 1rem;
  z-index: 10;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .project-modal {
    width: 70%;
  }
}

@media only screen and (max-width: 1100px) {
  .project-modal {
    width: 80%;
  }
  .project--display {
    margin: 2rem;
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 1000px) {
  .project-modal {
    width: 95%;
  }
}

@media only screen and (max-width: 720px) {
  .project-modal--images {
    height: 40%;
  }
  .project-modal--details {
    height: 60%;
    padding-top: 6rem;
  }

  .extra {
    visibility: hidden;
    opacity: 0;
  }

  .project-modal--extra-links {
    min-width: 100%;
  }
}

@media only screen and (max-width: 660px) {
  .project-modal--title {
    font-size: 4rem;
  }
  .project--display {
    margin: 1rem;
  }

  .project-modal--link {
    margin: 0 1rem;
  }
}

@media only screen and (max-width: 560px) {
  .project-modal--link {
    font-size: 1.6rem;
    padding: 0.5rem 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .project-modal--title {
    font-size: 3rem;
  }
  .project-modal--link {
    flex: 1;
  }
  .project-modal--link-icon {
    min-width: 100%;
  }
}

@media only screen and (max-width: 440px) {
  .project-modal--link {
    width: 100%;
    flex: initial;
    text-align: center;
    margin: 0.5rem 0;
  }
  .project-modal--link-icon {
    min-width: auto;
  }
  .project-modal--details {
    padding: 3rem;
    padding-top: 4rem;
  }
}

/* TIMESTAMPS */
.project-modal--timestamps {
  position: absolute;
  top: 4rem;
  right: 4rem;
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  color: rgb(100, 100, 100);
}
.project-modal--timestamps span:not(:last-child) {
  margin-bottom: 0.5rem;
}
.project-modal--timestamp-icon {
  margin-right: 1rem;
}
