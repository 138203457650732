@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

footer {
  display: grid;
  color: white;
  padding: 5rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 4rem;
  font-family: "PT Sans", sans-serif;
  background-color: rgb(30, 31, 39);
}

.footer-name {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  /* border: solid; */
}

.footer-name h1 {
  margin: 0;
  text-align: left;
  border-bottom: 1px solid;
  display: inline-block;
  padding-bottom: 1rem;
  font-size: 5rem;
  letter-spacing: 2px;
  white-space: nowrap;
}

.footer-name h3 {
  padding-top: 1rem;
}

.copyright {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  line-height: 1.8;
  letter-spacing: 1px;
}

.footer-links {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  text-align: center;
  font-size: 4rem;
  /* border: solid; */
  margin: auto 0;
  display: flex;
  justify-content: space-between;
}

.footer-links a {
  color: white;
  transition: all 0.3s;
  opacity: 0.8;
}

.linkedin {
  color: rgb(49, 119, 176);
  background-color: white;
  background-clip: content-box;
}

.footer-links a:hover {
  transform: scale(1.3);
  opacity: 1;
}

.icon-img {
  height: 4rem;
  /* margin-top: 5px; */
}

@media only screen and (max-width: 1200px) {
  .footer-links {
    grid-column: 1 / 4;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 900px) {
  .copyright {
    grid-column: 2 / 4;
    margin: 1rem 0 1rem 8rem;
  }
}

@media only screen and (max-width: 730px) {
  .footer-name {
    grid-column: 1 / 4;
  }

  .copyright {
    grid-row: 2 / 3;
    grid-column: 1 / 4;
    margin: 0;
  }

  .footer-links {
    grid-row: 3 / 4;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 475px) {
  footer {
    padding: 5rem 4rem;
  }
  .footer-name h1 {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 300px) {
  footer {
    grid-template-rows: repeat(2, auto);
  }
}
