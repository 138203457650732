@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap");

.skills {
  font-family: "PT Sans", sans-serif;
  position: relative;
  /* border: solid; */
  color: white;
  padding: 5rem 0px 10rem 0px;
  background-color: rgb(37, 39, 51);
}
.skills--grid {
  display: grid;
  row-gap: 10rem;
  grid-template-columns: 80% 20%;
}

.skills--list {
  margin: 0 5rem;
  font-size: 2rem;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  transition: 2s all;
  /* border: solid; */
}

.fade-from-right {
  opacity: 0;
  visibility: hidden;
  transform: translateX(15rem);
}
.fade-from-left {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-15rem);
}
.fade-from-bottom {
  opacity: 0;
  visibility: hidden;
  transform: translateY(15rem);
}

.top {
  grid-row: 2/3;
}

.skills--subtitle {
  /* border: solid; */
  grid-column: 2/3;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  transform: rotate(-90deg);
  margin: 8rem auto;
  height: 5rem;
}

.skills--skill {
  margin: 5rem;
}

.skills-box {
  /* border: solid; */
  width: 85%;
  height: 70%;
  margin: auto;
  display: grid;
  grid-template-columns: 20% 30% 30% 20%;
  grid-template-rows: repeat(5, 1fr);
  column-gap: 10rem;
  row-gap: 5rem;
  justify-content: center;
}

.skills-box div {
  padding: 3rem;
  border-radius: 3px;
  border: solid thin;
  border-color: transparent;
  margin: auto;
  width: 90%;
  background-color: rgba(52, 52, 52, 0.8);
  -webkit-box-shadow: 0px 0px 48px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 48px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 48px -15px rgba(0, 0, 0, 0.75);
  transition: all 0.4s ease;
}

.skills-box div:hover {
  /* border: solid thin; */
  /* border-color: #da4167; */
  transform: scale(1.1) translateY(-8%);
  box-shadow: 0px 10px 58px -15px rgba(0, 0, 0, 0.75);
}

.skills-box h3 {
  color: #da4167;
  text-align: center;
  font-size: 2.2rem;
  margin: 1.5rem 0;
}

.skill-icon {
  font-size: 3.6rem;
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;
  position: relative;
}

.languages {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
}
.frameworks {
  grid-column: 3 / 4;
  grid-row: 2 / 4;
}

.databases {
  grid-column: 2 / 3;
  grid-row: 3 / 5;
}
.tools {
  grid-column: 3 / 4;
  grid-row: 4 / 6;
}

.skills-box p {
  word-wrap: break-word;
  text-align: center;
  /* margin: auto; */
}

.skills-box p span {
  padding: 5px 10px;
  line-height: 2;
  background-color: rgb(75, 75, 75);
  border-radius: 5px;
}
@media only screen and (max-width: 1439px) {
  .skills {
    height: 100%;
  }
  .skills-box {
    width: 100%;
  }
}

@media only screen and (max-width: 1100px) {
  .skills-box {
    grid-template-columns: 10% 40% 40% 10%;
    column-gap: 4rem;
  }
}

@media only screen and (max-width: 800px) {
  .skills-box {
    grid-template-columns: 5% 45% 45% 5%;
    column-gap: 2rem;
  }
}
@media only screen and (max-width: 625px) {
  .skills {
    height: 100%;
  }
  .skills-box {
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, auto);
    row-gap: 4rem;
    margin-bottom: 5rem;
  }

  .languages {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  .frameworks {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .databases {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
  .tools {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }
}
