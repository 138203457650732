@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
body {
  background-color: rgb(41, 41, 41);
  overflow-y: scroll;
  overflow-x: hidden;
}

.section-landing {
  background-color: rgb(41, 41, 41);
  position: relative;
  height: 100vh;
}

.bg-video__content {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
  position: absolute;
  z-index: 0;
}

.landing--intro {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  font-family: "Ubuntu", sans-serif;
  font-size: 7.5rem;
  text-align: center;
  color: rgb(218, 218, 218);
  z-index: 10;
}
.pink {
  color: #da4167;
}

.landing--btn {
  padding: 1rem 2rem;
  font-size: 2rem;
  font-family: "Ubuntu", sans-serif;
  background-color: transparent;
  color: rgb(218, 218, 218);
  border: 3px solid rgb(218, 218, 218);
  border-radius: 2px;
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
}

.landing--btn:hover,
.profiles--links a:hover {
  background-color: rgba(218, 218, 218, 0.1);
  transform: scale(1.05) translateY(-2px);
  box-shadow: 4px 10px 16px rgba(0, 0, 0, 0.2);
}

.landing--btn:focus {
  outline: none;
  background-color: rgba(218, 218, 218, 0.1);
  transform: scale(1.05) translateY(0px);
}

.profiles {
  /* border: solid white; */
  height: auto;
  background-image: linear-gradient(
    to bottom right,
    rgb(24, 25, 36),
    rgb(54, 55, 71)
  );
}

.section--title {
  /* border: solid white; */
  font-size: 4rem;
  margin: 0;
  padding: 8rem;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: white;
  text-align: center;
}

.profiles--links {
  text-align: center;
  font-size: 3rem;
  /* border: solid; */
  margin: auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 8rem;
}

.profiles--links a {
  text-decoration: none;
  color: rgb(218, 218, 218);
  border: 3px solid rgb(218, 218, 218);
  border-radius: 4px;
  transition: all 0.2s ease;
  cursor: pointer;
  padding: 1rem 2rem;
  font-family: "Ubuntu", sans-serif;
  margin: 2rem;
}

.landing--icon-img {
  height: 25px;
  opacity: 0.9;
}

.projects {
  position: relative;
  height: 100%;
  /* border: solid; */
  color: white;
  padding: 50px 0px 100px 0px;
  background-color: rgb(67, 70, 86);
  font-family: "Montserrat", sans-serif;
}

.project-menu {
  /* border: solid; */
  text-align: center;
}

.project-menu button {
  font-family: "Montserrat", sans-serif;
  color: rgb(199, 199, 199);
  background-color: transparent;
  font-size: 2rem;
  padding: 1rem 0;
  margin: 1rem 3rem;
  /* border-radius: 5px; */
  cursor: pointer;
  border: none;
  border-bottom: solid;
  border-color: transparent;
  transition: all 0.2s ease;
}

.project-menu button:hover {
  border-color: #da4167;
}

.project-menu button:focus {
  outline: none;
}

button.active {
  border-color: #da4167;
  color: #da4167;
  font-weight: 600;
}

/********** Project Display **********/

.project--display {
  display: flex;
  flex-wrap: wrap;
  margin: 10rem;
  margin-top: 2rem;
  /* border: solid; */
  justify-content: center;
}

.project--container {
  width: 40rem;
  height: 40rem;
  border-radius: 5px;
  margin: 2rem;
  box-shadow: 4px 15px 20px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 0;
}
.project--img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  z-index: 0;
}

.project--container-overlay {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: 0.3s all ease;
  border-radius: 5px;
}

.project--container-overlay:hover {
  -webkit-backdrop-filter: blur(5px) brightness(0.6);
          backdrop-filter: blur(5px) brightness(0.6);
}

.project--btn {
  align-self: center;
  border: white solid;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  border-radius: 2px;
  padding: 1rem 2rem;
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  transition: 1s all ease, 0.2s background-color;
  cursor: pointer;
}

/* TODO: button is fading out after white stuff */
.project--container-overlay:hover .project--btn {
  visibility: visible;
  opacity: 1;
}

.project--btn:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgb(63, 63, 63);
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  transition: all 1s;
}
.show {
  opacity: 0.7;
  z-index: 3;
  visibility: visible;
}

/* project-modal */

.project-modal {
  background-color: rgb(235, 235, 235);
  display: block;
  position: fixed;
  height: 95%;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 5px;
  opacity: 1;
  transition: all 1s ease;
}

.hide {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.project-modal--images {
  width: 100%;
  height: 50%;
  background-color: black;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.project-modal--image {
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  object-fit: contain;
  position: absolute;
  z-index: 0;
}

.project-modal--details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 4rem;
  height: 50%;
  color: rgb(18, 18, 18);
  overflow-y: scroll;
  overflow-x: hidden;
}

.project-modal--title {
  font-size: 4.5rem;
  font-weight: 500;
  font-family: "Ubuntu", sans-serif;
}

.project-modal--description {
  line-height: 1.5;
}

.project-modal--notes {
  font-size: 1.4rem;
  line-height: 1.5;
  padding: 1rem;
  border-radius: 3px;
  background-color: rgb(225, 225, 225);
}

.project--stack {
  margin: 0rem 3rem;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  visibility: none;
  opacity: 0;
  transition: all 1.2s;
}

.project--container-overlay:hover .project--stack {
  visibility: visible;
  opacity: 1;
  /* transform: translateY(50%); */
}

.project--stack span {
  margin: 1rem 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
}

.project-modal--links {
  /* border: solid; */
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.project-modal--link {
  text-decoration: none;
  font-size: 1.8rem;
  padding: 1rem 2rem;
  color: white;
  background-color: rgb(111, 117, 151);
  border: solid;
  border-color: rgb(111, 117, 151);
  margin: 0 1rem;
  transition: all 0.1s ease;
}

.project-modal--link:hover {
  background-color: rgb(125, 132, 168);
  border-color: rgb(125, 132, 168);
}

.transparent {
  background-color: transparent;
  color: rgb(93, 98, 128);
  font-weight: 600;
}

.transparent:hover {
  background-color: rgba(111, 117, 151, 0.1);
  color: rgb(93, 98, 128);
}

.project-modal--extra-links {
  /* border: solid; */
  margin: 0 -1rem;
  align-self: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.project-modal--link-icon {
  font-size: 1.4rem;
  margin-bottom: 2px;
}

.big-icon {
  font-size: 2.2rem;
  /* border: solid; */
  vertical-align: middle;
}

.extra-big-icon {
  font-size: 5rem;
  margin: 0 2rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.extra-big-icon:hover {
  transform: scale(1.2);
}

.youtube {
  color: rgb(235, 50, 35);
}

.trello {
  color: rgb(43, 105, 162);
}

.project-modal--exit {
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  font-size: 3rem;
  top: 1rem;
  right: 1rem;
  z-index: 10;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .project-modal {
    width: 70%;
  }
}

@media only screen and (max-width: 1100px) {
  .project-modal {
    width: 80%;
  }
  .project--display {
    margin: 2rem;
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 1000px) {
  .project-modal {
    width: 95%;
  }
}

@media only screen and (max-width: 720px) {
  .project-modal--images {
    height: 40%;
  }
  .project-modal--details {
    height: 60%;
    padding-top: 6rem;
  }

  .extra {
    visibility: hidden;
    opacity: 0;
  }

  .project-modal--extra-links {
    min-width: 100%;
  }
}

@media only screen and (max-width: 660px) {
  .project-modal--title {
    font-size: 4rem;
  }
  .project--display {
    margin: 1rem;
  }

  .project-modal--link {
    margin: 0 1rem;
  }
}

@media only screen and (max-width: 560px) {
  .project-modal--link {
    font-size: 1.6rem;
    padding: 0.5rem 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .project-modal--title {
    font-size: 3rem;
  }
  .project-modal--link {
    flex: 1 1;
  }
  .project-modal--link-icon {
    min-width: 100%;
  }
}

@media only screen and (max-width: 440px) {
  .project-modal--link {
    width: 100%;
    flex: initial;
    text-align: center;
    margin: 0.5rem 0;
  }
  .project-modal--link-icon {
    min-width: auto;
  }
  .project-modal--details {
    padding: 3rem;
    padding-top: 4rem;
  }
}

/* TIMESTAMPS */
.project-modal--timestamps {
  position: absolute;
  top: 4rem;
  right: 4rem;
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  color: rgb(100, 100, 100);
}
.project-modal--timestamps span:not(:last-child) {
  margin-bottom: 0.5rem;
}
.project-modal--timestamp-icon {
  margin-right: 1rem;
}

.skills {
  font-family: "PT Sans", sans-serif;
  position: relative;
  /* border: solid; */
  color: white;
  padding: 5rem 0px 10rem 0px;
  background-color: rgb(37, 39, 51);
}
.skills--grid {
  display: grid;
  grid-row-gap: 10rem;
  row-gap: 10rem;
  grid-template-columns: 80% 20%;
}

.skills--list {
  margin: 0 5rem;
  font-size: 2rem;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  transition: 2s all;
  /* border: solid; */
}

.fade-from-right {
  opacity: 0;
  visibility: hidden;
  transform: translateX(15rem);
}
.fade-from-left {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-15rem);
}
.fade-from-bottom {
  opacity: 0;
  visibility: hidden;
  transform: translateY(15rem);
}

.top {
  grid-row: 2/3;
}

.skills--subtitle {
  /* border: solid; */
  grid-column: 2/3;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  transform: rotate(-90deg);
  margin: 8rem auto;
  height: 5rem;
}

.skills--skill {
  margin: 5rem;
}

.skills-box {
  /* border: solid; */
  width: 85%;
  height: 70%;
  margin: auto;
  display: grid;
  grid-template-columns: 20% 30% 30% 20%;
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 10rem;
  -webkit-column-gap: 10rem;
          column-gap: 10rem;
  grid-row-gap: 5rem;
  row-gap: 5rem;
  justify-content: center;
}

.skills-box div {
  padding: 3rem;
  border-radius: 3px;
  border: solid thin;
  border-color: transparent;
  margin: auto;
  width: 90%;
  background-color: rgba(52, 52, 52, 0.8);
  box-shadow: 0px 0px 48px -15px rgba(0, 0, 0, 0.75);
  transition: all 0.4s ease;
}

.skills-box div:hover {
  /* border: solid thin; */
  /* border-color: #da4167; */
  transform: scale(1.1) translateY(-8%);
  box-shadow: 0px 10px 58px -15px rgba(0, 0, 0, 0.75);
}

.skills-box h3 {
  color: #da4167;
  text-align: center;
  font-size: 2.2rem;
  margin: 1.5rem 0;
}

.skill-icon {
  font-size: 3.6rem;
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;
  position: relative;
}

.languages {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
}
.frameworks {
  grid-column: 3 / 4;
  grid-row: 2 / 4;
}

.databases {
  grid-column: 2 / 3;
  grid-row: 3 / 5;
}
.tools {
  grid-column: 3 / 4;
  grid-row: 4 / 6;
}

.skills-box p {
  word-wrap: break-word;
  text-align: center;
  /* margin: auto; */
}

.skills-box p span {
  padding: 5px 10px;
  line-height: 2;
  background-color: rgb(75, 75, 75);
  border-radius: 5px;
}
@media only screen and (max-width: 1439px) {
  .skills {
    height: 100%;
  }
  .skills-box {
    width: 100%;
  }
}

@media only screen and (max-width: 1100px) {
  .skills-box {
    grid-template-columns: 10% 40% 40% 10%;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
            column-gap: 4rem;
  }
}

@media only screen and (max-width: 800px) {
  .skills-box {
    grid-template-columns: 5% 45% 45% 5%;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }
}
@media only screen and (max-width: 625px) {
  .skills {
    height: 100%;
  }
  .skills-box {
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, auto);
    grid-row-gap: 4rem;
    row-gap: 4rem;
    margin-bottom: 5rem;
  }

  .languages {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  .frameworks {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .databases {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
  .tools {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }
}

.contact {
  font-family: "PT Sans", sans-serif;
  position: relative;
  /* border: solid; */
  color: rgb(216, 216, 216);
  padding: 50px 0px 50px 0px;
  background-color: rgb(37, 39, 51);
}

/* TODO: fix shifting text boxes when error appears
 */
.message {
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-row-gap: 2rem;
  row-gap: 2rem;
  /* border: solid; */
  position: relative;
  font-size: 2rem;
  padding: 8rem;
  width: 50%;
  margin: 7rem auto;
  height: 60rem;
  background-color: rgb(67, 70, 86);
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.3);
}

input,
textarea {
  background-color: transparent;
  width: 100%;
  color: rgb(213, 213, 213);
  border: none;
  border-bottom: 2px solid;
  border-color: rgb(157, 157, 157);
  padding: 1rem;
  font-size: 2rem;
  transition: all 0.2s;
}

input:focus,
textarea:focus {
  border-color: #da4167;
  outline: none;
}

.error {
  color: rgb(184, 86, 86);
  margin-left: 1rem;
  font-size: 1.6rem;
  font-weight: bold;
}

.body {
  grid-row: 3 / 4;
  /* border: solid; */
}
.body textarea {
  border-color: rgb(157, 157, 157);
  word-wrap: normal;
  font-family: inherit;
}

.body textarea:focus {
  border-color: #da4167;
}

.message button {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  margin: 0px auto auto 0px;
  background-color: transparent;
  border: white solid;
  color: white;
  padding: 1rem 3rem;
  min-width: 80px;
  border-radius: 3px;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.message button:hover {
  border: #da4167 solid;
  color: #da4167;
}
.message button:focus {
  outline: none;
}
.email-response {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  margin: auto;
}

@media only screen and (max-width: 1200px) {
  .message {
    width: 70%;
  }
}

@media only screen and (max-width: 800px) {
  .message {
    width: 90%;
  }

  .email-response {
    font-size: 1.2rem;
    margin-left: 50%;
  }
}

@media only screen and (max-width: 650px) {
  .message {
    width: 90%;
    padding: 8rem 4rem;
  }
}

footer {
  display: grid;
  color: white;
  padding: 5rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 4rem;
  row-gap: 4rem;
  font-family: "PT Sans", sans-serif;
  background-color: rgb(30, 31, 39);
}

.footer-name {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  /* border: solid; */
}

.footer-name h1 {
  margin: 0;
  text-align: left;
  border-bottom: 1px solid;
  display: inline-block;
  padding-bottom: 1rem;
  font-size: 5rem;
  letter-spacing: 2px;
  white-space: nowrap;
}

.footer-name h3 {
  padding-top: 1rem;
}

.copyright {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  line-height: 1.8;
  letter-spacing: 1px;
}

.footer-links {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  text-align: center;
  font-size: 4rem;
  /* border: solid; */
  margin: auto 0;
  display: flex;
  justify-content: space-between;
}

.footer-links a {
  color: white;
  transition: all 0.3s;
  opacity: 0.8;
}

.linkedin {
  color: rgb(49, 119, 176);
  background-color: white;
  background-clip: content-box;
}

.footer-links a:hover {
  transform: scale(1.3);
  opacity: 1;
}

.icon-img {
  height: 4rem;
  /* margin-top: 5px; */
}

@media only screen and (max-width: 1200px) {
  .footer-links {
    grid-column: 1 / 4;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 900px) {
  .copyright {
    grid-column: 2 / 4;
    margin: 1rem 0 1rem 8rem;
  }
}

@media only screen and (max-width: 730px) {
  .footer-name {
    grid-column: 1 / 4;
  }

  .copyright {
    grid-row: 2 / 3;
    grid-column: 1 / 4;
    margin: 0;
  }

  .footer-links {
    grid-row: 3 / 4;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 475px) {
  footer {
    padding: 5rem 4rem;
  }
  .footer-name h1 {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 300px) {
  footer {
    grid-template-rows: repeat(2, auto);
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

/* Defines 1rem (10px) */
html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-size: 1.6rem;
}

@media only screen and (max-width: 920px) {
  html {
    font-size: 55%;
  }
}

@media only screen and (max-width: 320px) {
  html {
    font-size: 52%;
  }
}
