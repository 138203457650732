@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap");

body {
  background-color: rgb(41, 41, 41);
  overflow-y: scroll;
  overflow-x: hidden;
}

.section-landing {
  background-color: rgb(41, 41, 41);
  position: relative;
  height: 100vh;
}

.bg-video__content {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
  position: absolute;
  z-index: 0;
}

.landing--intro {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  font-family: "Ubuntu", sans-serif;
  font-size: 7.5rem;
  text-align: center;
  color: rgb(218, 218, 218);
  z-index: 10;
}
.pink {
  color: #da4167;
}

.landing--btn {
  padding: 1rem 2rem;
  font-size: 2rem;
  font-family: "Ubuntu", sans-serif;
  background-color: transparent;
  color: rgb(218, 218, 218);
  border: 3px solid rgb(218, 218, 218);
  border-radius: 2px;
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
}

.landing--btn:hover,
.profiles--links a:hover {
  background-color: rgba(218, 218, 218, 0.1);
  transform: scale(1.05) translateY(-2px);
  -webkit-box-shadow: 4px 10px 16px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 4px 10px 16px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 10px 16px rgba(0, 0, 0, 0.2);
}

.landing--btn:focus {
  outline: none;
  background-color: rgba(218, 218, 218, 0.1);
  transform: scale(1.05) translateY(0px);
}

.profiles {
  /* border: solid white; */
  height: auto;
  background-image: linear-gradient(
    to bottom right,
    rgb(24, 25, 36),
    rgb(54, 55, 71)
  );
}

.section--title {
  /* border: solid white; */
  font-size: 4rem;
  margin: 0;
  padding: 8rem;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: white;
  text-align: center;
}

.profiles--links {
  text-align: center;
  font-size: 3rem;
  /* border: solid; */
  margin: auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 8rem;
}

.profiles--links a {
  text-decoration: none;
  color: rgb(218, 218, 218);
  border: 3px solid rgb(218, 218, 218);
  border-radius: 4px;
  transition: all 0.2s ease;
  cursor: pointer;
  padding: 1rem 2rem;
  font-family: "Ubuntu", sans-serif;
  margin: 2rem;
}

.landing--icon-img {
  height: 25px;
  opacity: 0.9;
}
