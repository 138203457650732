@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

.contact {
  font-family: "PT Sans", sans-serif;
  position: relative;
  /* border: solid; */
  color: rgb(216, 216, 216);
  padding: 50px 0px 50px 0px;
  background-color: rgb(37, 39, 51);
}

/* TODO: fix shifting text boxes when error appears
 */
.message {
  display: grid;
  grid-template-rows: repeat(4, auto);
  row-gap: 2rem;
  /* border: solid; */
  position: relative;
  font-size: 2rem;
  padding: 8rem;
  width: 50%;
  margin: 7rem auto;
  height: 60rem;
  background-color: rgb(67, 70, 86);
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.3);
}

input,
textarea {
  background-color: transparent;
  width: 100%;
  color: rgb(213, 213, 213);
  border: none;
  border-bottom: 2px solid;
  border-color: rgb(157, 157, 157);
  padding: 1rem;
  font-size: 2rem;
  transition: all 0.2s;
}

input:focus,
textarea:focus {
  border-color: #da4167;
  outline: none;
}

.error {
  color: rgb(184, 86, 86);
  margin-left: 1rem;
  font-size: 1.6rem;
  font-weight: bold;
}

.body {
  grid-row: 3 / 4;
  /* border: solid; */
}
.body textarea {
  border-color: rgb(157, 157, 157);
  word-wrap: normal;
  font-family: inherit;
}

.body textarea:focus {
  border-color: #da4167;
}

.message button {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  margin: 0px auto auto 0px;
  background-color: transparent;
  border: white solid;
  color: white;
  padding: 1rem 3rem;
  min-width: 80px;
  border-radius: 3px;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.message button:hover {
  border: #da4167 solid;
  color: #da4167;
}
.message button:focus {
  outline: none;
}
.email-response {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  margin: auto;
}

@media only screen and (max-width: 1200px) {
  .message {
    width: 70%;
  }
}

@media only screen and (max-width: 800px) {
  .message {
    width: 90%;
  }

  .email-response {
    font-size: 1.2rem;
    margin-left: 50%;
  }
}

@media only screen and (max-width: 650px) {
  .message {
    width: 90%;
    padding: 8rem 4rem;
  }
}
